import React from 'react';
import RxButton from "../components/RxButton"
function AboutSix() {
  return (
    <div>
      <>
        {/* short description about company area */}
        <div className="short-discription-area tmp-section-gapBottom" style={{marginTop: 50}}>
          <div className="container plr_sm--15">
            <div className="row">
              <div className="col-lg-12">
                <div className="short-discription-between-area">
                  <h2 className="title wow fadeInUp pullout-text" data-wow-duration=".8s" data-wow-delay=".1s">
                  MNATION Advisory’s mission is to help empower Muslims in the private and the public sector through our strategic media and marcomms advisory services
                  </h2>
                  <div className="discription-area">
                    <p className="disc wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".1s">
                    Our team is dedicated to helping you to navigate complex landscapes, ensuring your initiatives are delivered on target, on message and to maximum impact. </p>
                    <p className="disc wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".1s">
                    Our suite of media and marcomms advisory services is designed to cater to all your needs.   </p>
                    <p className="disc wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".1s">
                    From strategic media planning and public relations to crisis management, policy planning or market and business development, our talented team will work closely with you to understand your pain points and goals to craft tailored solutions that align with your vision and drive measurable results.    </p>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* short description about company area end */}

        {/* about company wrapper */}
        <section className="about-company-area tmp-section-gapBottom">
          <div className="row align-items-center plr_sm--15 plr_md--15">
            <div className="col-lg-4 offset-lg-2 mb_md--50 mb_sm--30">
              <div className="split-inner">
                <h2 className="title sal-animate mb--40 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".1s">
                  Building Your Brand’s Reputation
                </h2>
                <p className="description sal-animate mb--25 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".3s">
                  On the PR frontlines, we excel at building and maintaining your brand’s reputation. Our editorial team crafts compelling narratives that resonate with your target audience, securing media placements that will increase your brand’s awareness and on-market equity.
                </p>
                <p className="description sal-animate wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".5s">
                  We also provide media mentoring to ensure our clients present their best with poise and passion in the public arena. Additionally, our expertise in policy planning and development allows us to support your organization in creating robust policies that protect your brand’s integrity and compliance.
                </p>
                <p className="description sal-animate wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".5s">
                  Contact our team to discuss how MNATION Advisory can help you to achieve your business objectives today.
                </p>
                <RxButton/>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInRight" data-wow-duration=".8s" data-wow-delay=".3s">
              <div className="thumbnail image-right-content">
                <img src="assets/images/about/microphone.jpg" alt="split Images" />
              </div>
            </div>
          </div>
        </section>
        {/* about company wrapper end */}
      </>
    </div>
  );
}

export default AboutSix;
