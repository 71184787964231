import React, { useState } from 'react';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

function RxButton({ Text="Contact us", maxWidth=450,url="/Contact", className=""}) {
    const [hovered, setHovered] = useState(false);
    const navigate = useNavigate()
  return (
    <Button
    type="primary"
    icon={hovered?<RightOutlined style={{ marginLeft: 8 }}/>:null}
    iconPosition='end'
    onClick={()=>navigate(url)}
    size='large'
    className={className}
    block

    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
   style={{maxWidth: maxWidth, height: 50, alignSelf:"center", boxShadow:null}}
  >
    {Text}
  </Button>
  )
}

export default RxButton