import React, { useState } from "react";
import { Link } from "react-router-dom";
import RxButton from "../components/RxButton";

function AboutFour() {
  const [isOpen, setIsOpen] = useState(false);

  const openVideo = () => {
    setIsOpen(true);
  };

  const closeVideo = () => {
    setIsOpen(false);
  };

  const handleKeyUp = (e) => {
    if (e.key === "Escape") {
      closeVideo();
    }
  };

  return (
    <div>
      {/* Tpm About Area Start  */}
      <div className="about-area tmp-section-gapBottom about-style-one">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                src="assets/images/about/light_bulb.jpg"
                alt="Corporate_service"
              />
            </div>
            <div className="col-lg-6">
              <div className="about-inner" >
                <div className="section-head text-align-left section-head-one-side">
                  <div className="section-sub-title">
                    <span className="subtitle">ABOUT MNATION ADVISORY</span>
                  </div>
                  <h2 className="title split-collab">
                    Unlock the Potential of Your Brand with MNATION Advisory
                  </h2>
                </div>

                {/* Main Description */}
                <p
                  className="description"
                  data-sal-delay={200}
                  data-sal="slide-up"
                  data-sal-duration={800}
                  style={{ lineHeight: "1.6" }}
                >
                  Unlock the potential of your brand with MNATION Advisory's
                  media and marcomms advisory services. From strategic planning
                  to seamless execution, we bridge the gap between your vision
                  and market reality.
                </p>
                <p className="description" style={{ lineHeight: "1.6" }}>
                  Whether it's change-management, acquisition or merger
                  communications, crisis PR, policy planning, market or business
                  development, martech or content generation, MNATION Advisory
                  is your trusted media partner.
                </p>
                <p className="description" style={{ lineHeight: "1.6" }}>
                  We tailor solutions to your needs, ensuring impactful
                  engagement and sustained growth.
                </p>
                <p className="description" style={{ lineHeight: "1.6" }}>
                  We navigate complex landscapes and deliver insights to drive
                  decisions that will increase your brand's on-market equity.
                </p>
                <p className="description" style={{ lineHeight: "1.6" }}>
                  Elevate your business strategy. Transform challenges into
                  opportunities.
                </p>
                <p className="description" style={{ fontStyle: "italic" }}>
                  Contact us to discuss how MNATION Advisory can help you to
                  achieve your objectives today.
                </p>

                {/* Contact Us */}
                
                
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Tpm About Area End  */}
    </div>
  );
}

export default AboutFour;
