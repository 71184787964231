import React from 'react'
import { Link } from 'react-router-dom';

function Nav(){
    return(
        <div>
            <div className="header-nav main-nav-one">
                <nav>
                    <ul className="parent-nav">
                        <li>
                            <Link className="nav-link" to="/">
                                <span className="rolling-text"><div className="block"><span className="letter">H</span><span className="letter">O</span><span className="letter">M</span><span className="letter">E</span></div><div className="block"><span className="letter">H</span><span className="letter">O</span><span className="letter">M</span><span className="letter">E</span></div></span>
                            </Link>
                           
                        </li>
                        <li>
                            <Link className="nav-link" to="/About">
                                <span className="rolling-text"><div className="block"><span className="letter">A</span><span className="letter">B</span><span className="letter">O</span><span className="letter">U</span><span className="letter">T</span></div><div className="block"><span className="letter">A</span><span className="letter">B</span><span className="letter">O</span><span className="letter">U</span><span className="letter">T</span></div></span>
                            </Link>
                        </li>
                       
                        <li >
                            <Link className="nav-link" to="/Service">
                                <span className="rolling-text"><div className="block"><span className="letter">S</span><span className="letter">E</span><span className="letter">R</span><span className="letter">V</span><span className="letter">I</span><span className="letter">C</span><span className="letter">E</span><span className="letter">S</span></div><div className="block"><span className="letter">S</span><span className="letter">E</span><span className="letter">R</span><span className="letter">V</span><span className="letter">I</span><span className="letter">C</span><span className="letter">E</span><span className="letter">S</span></div></span>
                            </Link>
                           
                        </li>
                        <li>
                            <Link className="nav-link" to="/Contact">
                                <span className="rolling-text"><div className="block"><span className="letter">C</span><span className="letter">O</span><span className="letter">N</span><span className="letter">T</span><span className="letter">A</span><span className="letter">C</span><span className="letter">T</span></div><div className="block"><span className="letter">C</span><span className="letter">O</span><span className="letter">N</span><span className="letter">T</span><span className="letter">A</span><span className="letter">C</span><span className="letter">T</span></div></span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}


export default Nav