
import './App.css';
import Home from './home/Home';
import About from './inner/About';
import Service from './inner/Service';
import ServiceDetails from './inner/ServiceDetails';
import Blog from './inner/Blog';
import BlogColOne from './inner/BlogColOne';
import BlogColTwo from './inner/BlogColTwo';
import BlogDetails from './inner/BlogDetails';
import BlogDetailsTwo from './inner/BlogDetailsTwo';
import Appoinment from './inner/Appoinment';
import ProjectMesonary from './inner/ProjectMesonary';
import ProjectMesonaryThree from './inner/ProjectMesonaryThree';
import Project from './inner/Project';
import ProjectSlider from './inner/ProjectSlider';
import ProjectDetails from './inner/ProjectDetails';
import ProjectDetailsTwo from './inner/ProjectDetailsTwo';
import TeamOne from './inner/TeamOne';
import TeamTwo from './inner/TeamTwo';
import TeamDetails from './inner/TeamDetails';
import Pricing from './inner/Pricing';
import FaqOne from './inner/FaqOne';
import Error from './inner/Error';
import Contact from './inner/Contact';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import LoadTop from './components/LoadTop';
import BackTop from './components/BackTop';
import CurrentLink from './components/CurrentLink';
import Preloader from './components/Preloader';

function App() {

  
  return (
    <div className="App">

      <Router>

        <LoadTop />
        <CurrentLink />
        <BackTop />
        
       

        <Routes>
          <Route path="/" element={<Home/>}> </Route>
          <Route path="/About" element={<About/>}> </Route>
          <Route path="/Service" element={<Service/>}> </Route>
          {/* <Route path="/ServiceDetails" element={<ServiceDetails/>}> </Route>
          <Route path="/Blog" element={<Blog/>}> </Route>
          <Route path="/BlogColOne" element={<BlogColOne/>}> </Route>
          <Route path="/BlogColTwo" element={<BlogColTwo/>}> </Route>
          <Route path="/BlogDetails" element={<BlogDetails/>}> </Route>
          <Route path="/BlogDetailsTwo" element={<BlogDetailsTwo/>}> </Route>
          <Route path="/Appoinment" element={<Appoinment/>}> </Route>
          <Route path="/Project" element={<Project/>}> </Route>
          <Route path="/ProjectMesonary" element={<ProjectMesonary/>}> </Route>
          <Route path="/ProjectMesonaryThree" element={<ProjectMesonaryThree/>}> </Route>
          <Route path="/ProjectSlider" element={<ProjectSlider/>}> </Route>
          <Route path="/ProjectDetails" element={<ProjectDetails/>}> </Route>
          <Route path="/ProjectDetailsTwo" element={<ProjectDetailsTwo/>}> </Route> */}
          {/* <Route path="/TeamOne" element={<TeamOne/>}> </Route>
          <Route path="/TeamTwo" element={<TeamTwo/>}> </Route> */}
          <Route path="/TeamDetails" element={<TeamDetails/>}> </Route>
          {/* <Route path="/Pricing" element={<Pricing/>}> </Route>
          <Route path="/FaqOne" element={<FaqOne/>}> </Route> */}
          <Route path="/Error" element={<Error/>}> </Route>
          <Route path="/Contact" element={<Contact/>}> </Route>
        </Routes>
      </Router>

    </div>
  );
}

export default App;


